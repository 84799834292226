<template>
    <Card>
        <div slot="title">{{ title }}</div>
        
        <Alert type="warning" v-if="isEdit == 'true'">提示:如果删改题目将导致原先参与者答案失效</Alert>
        <div style="margin-bottom: 15px" v-if="isDetail == 'true'">
            <div style="font-size: 28px">{{ formData.sys_user }}</div>
            <div v-if="isDetail == 'true'" style="position: absolute; top: 111px; left: 180px">
                <Tag color="primary" v-if="isDetail == 'true' && formData.process == 0" style="margin-left: 25px">未开始</Tag>
                <Tag color="success" v-if="isDetail == 'true' && formData.process == 1" style="margin-left: 25px">调研中</Tag>
                <Tag color="error" v-if="isDetail == 'true' && formData.process == 2" style="margin-left: 25px">已结束</Tag>
            </div>
        </div>
        <Card title="基础设置" dis-hover v-if="isDetail == 'true'">
            <div style="width: 100%; background-color: rgb(246, 246, 246); height: 180px; padding: 40px">
                <div style="display: flex; position: relative">
                    <div style="display: flex; position: absolute">
                        <div class="form_label">问卷名称：</div>
                        <div style="width: 200px">{{ formData.title }}</div>
                    </div>
                    <div style="display: flex; position: absolute; left: 25%">
                        <div class="form_label">创建人员：</div>
                        <div style="width: 200px">{{ formData.sys_user }}</div>
                    </div>
                    <div style="display: flex; position: absolute; left: 50%">
                        <div class="form_label">更新时间：</div>
                        <div style="width: 200px">{{ formData.updated_at }}</div>
                    </div>
                    <div style="display: flex; position: absolute; left: 75%">
                        <div class="form_label" style="width: 75px">问卷问题：</div>
                        <div style="width: 200px">{{ formData.ques_count }}个</div>
                    </div>
                </div>
                <div style="display: flex; margin-top: 60px; position: relative">
                    <div style="display: flex">
                        <div class="form_label">是否发布：</div>
                        <div style="width: 200px" v-if="formData.status == 1">是</div>
                        <div style="width: 200px" v-else>否</div>
                    </div>
                    <div style="display: flex; position: absolute; left: 25%">
                        <div class="form_label">开始时间：</div>
                        <div style="width: 200px">{{ formData.publish_at }}</div>
                    </div>
                    <div style="display: flex; position: absolute; left: 50%">
                        <div class="form_label">结束时间：</div>
                        <div style="width: 200px">{{ formData.ended_at }}</div>
                    </div>
                    <div style="display: flex; position: absolute; left: 75%">
                        <div class="form_label" style="width: 75px">问卷描述：</div>
                        <div style="width: 200px">{{ formData.intro }}</div>
                    </div>
                </div>
            </div>
        </Card>
        <Card title="基础设置" dis-hover v-else>
            <div class="form_item" v-if="isDetail == 'true'">
                <div class="form_label">创建人员</div>
                <div>{{ formData.sys_user }}</div>
            </div>
            <div class="form_item">
                <div class="form_label must_input">问卷名称</div>
                <div v-if="isDetail == 'true'">{{ formData.title }}</div>
                <Input class="width_300" v-else placeholder="请输入问卷名称" v-model="formData.title" />
            </div>
            <div v-if="isDetail == 'true'" style="position: absolute; left: 117px; top: 11px">
                <Tag color="primary" v-if="isDetail == 'true' && formData.process == 0" style="margin-left: 25px">未开始</Tag>
                <Tag color="success" v-if="isDetail == 'true' && formData.process == 1" style="margin-left: 25px">调研中</Tag>
                <Tag color="error" v-if="isDetail == 'true' && formData.process == 2" style="margin-left: 25px">已结束</Tag>
            </div>
            <div class="form_item">
                <div class="form_label">问卷描述</div>
                <div v-if="isDetail == 'true'">{{ formData.intro }}</div>
                <Input class="width_300" v-else type="textarea" placeholder="请输入问卷描述" v-model="formData.intro" />
            </div>
        </Card>
        <Card title="调研数据" dis-hover v-if="isDetail == 'true'">
            <div style="width: 100%; background-color: rgb(246, 246, 246); height: 120px; padding: 40px">
                <div style="display: flex; position: relative">
                    <div style="display: flex; position: absolute">
                        <div class="form_label">调研次数：</div>
                        <div>{{ formData.pv_count }}次</div>
                    </div>
                    <div style="display: flex; position: absolute; left: 25%">
                        <div class="form_label">调研人数：</div>
                        <div>{{ formData.uv_count }}人</div>
                    </div>
                </div>
            </div>
        </Card>
        <Card title="调研题目" dis-hover v-if="isDetail == 'true'">
            <Table :key="tableKey" stripe class="table mt_10" style="width: 100%" :columns="dataColumn" :data="questionsList">
                <template v-slot:id="{ row, index }">
                    <span>{{ index + 1 }}</span>
                </template>
                <template slot-scope="{ row }" slot="name">
                    <span v-if="row.type == 'tab1'">{{ row.title }}</span>
                    <span v-if="row.type == 'tab2'">{{ row.title }}</span>
                    <span v-if="row.type == 'tab3'">{{ row.title }}</span>
                    <span v-if="row.type == 'tab4'">{{ row.title }}</span>
                </template>
                <template slot-scope="{ row}" slot="type">
                    <span v-if="row.type == 'tab1'">单选题</span>
                    <span v-if="row.type == 'tab2'">多选题</span>
                    <span v-if="row.type == 'tab3'">简答题</span>
                    <span v-if="row.type == 'tab4'">比重题</span>
                </template>
                <template slot-scope="{ row}" slot="isrequire">
                    <span v-if="row.isrequire == '0' || row.isrequire == ''">否</span>
                    <span v-if="row.isrequire == '1'">是</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <span v-if="row.type == 'tab1'">
                        <span v-for="(item, index) in row.optionsArr" :key="index">
                            <span style="margin-left: 15px">{{ index + 1 }}.{{ item.content }}</span>
                        </span>
                    </span>
                    <span v-if="row.type == 'tab2'">
                        <span v-for="(item, index) in row.optionsArr" :key="index">
                            <span style="margin-left: 15px">{{ index + 1 }}.{{ item.content }}</span>
                        </span>
                    </span>
                    <span v-if="row.type == 'tab3'"></span>
                    <span v-if="row.type == 'tab4'">
                        <Rate v-model="ratevalue" :count="5" v-if="row.status == 1" />
                        <Rate v-model="ratevalue" :count="10" v-if="row.status == 2" />
                    </span>
                    <!-- <span v-if="row.type=='tab2'">{{row.title2}}</span>
                            <span v-if="row.type=='tab3'">{{row.title3}}</span>
                            <span v-if="row.type=='tab4'">{{row.title4}}</span> -->
                </template>
                <!-- <template slot-scope="{ row }" slot="type">
                            <span v-if="row.type == 1">社会合作伙伴</span>
                            <span v-if="row.type == 5">公司员工</span>
                            <span v-if="row.type == 2">经销商</span>
                            <span v-if="row.type == 3">小B端</span>
                            <span v-if="row.type == 4">个人</span>
                        </template>
                        <template slot-scope="{ row }" slot="manager">
                            <div class="table_manager flex flex_wrap align_center">
                                <Button type="success" size="small" ghost @click="onView(row)">详情</Button>
                                <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                                <Button type="primary" size="small" ghost @click="showpassword(row)">修改密码</Button>
                                <Button type="primary" v-if="row.status == 1" size="small" ghost @click="onPause(row)">暂停代理</Button>
                                <Button type="primary" v-else size="small" ghost @click="onDelete(row)">删除</Button>
                            </div>
                        </template> -->
            </Table>
        </Card>
        <Card title="问题设置" dis-hover v-if="isDetail != 'true'">
            <i-button class="ml_50" v-if="isDetail != 'true'" type="success" style="position: absolute; right: 20px; top: 10px" :loading="ajaxLoading" @click="onAdd">添加题目</i-button>
            <div v-for="(item, index) in questionsList" :key="index">
                <div style="display: flex; flex-direction: column; margin-left: 126px; margin-top: 30px" v-if="item.title != ''">
                    <div style="padding: 15px 0; border-bottom: 1px solid silver; position: relative">
                        <div class="form_label" v-if="item.type == 'tab1'">
                            <span v-if="item.isrequire == 1" style="color: red; margin-right: 2px">*</span>
                            <span v-if="isDetail != 'true' && isEdit!='true'">{{ index - 1 }}.</span>
                            <span v-if="isDetail == 'true' || isEdit == 'true'">{{ index + 1 }}.</span>
                            {{ item.title }}
                            <Tag color="primary" style="margin-left: 15px">单选题</Tag>
                        </div>
                        <div class="form_label" v-if="item.type == 'tab2'">
                            <span v-if="item.isrequire == 1" style="color: red; margin-right: 2px">*</span>
                            <span v-if="isDetail != 'true' && isEdit!='true'">{{ index - 1 }}.</span>
                            <span v-if="isDetail == 'true' || isEdit == 'true'">{{ index + 1 }}.</span>
                            {{ item.title }}
                            <Tag color="primary" style="margin-left: 15px">多选题</Tag>
                        </div>
                        <div class="form_label" v-if="item.type == 'tab3'">
                            <span v-if="item.isrequire == 1" style="color: red; margin-right: 2px">*</span>
                            <span v-if="isDetail != 'true' && isEdit!='true'">{{ index - 1 }}.</span>
                            <span v-if="isDetail == 'true' || isEdit == 'true'">{{ index + 1 }}.</span>
                            {{ item.title }}
                            <Tag color="primary" style="margin-left: 15px">问答题</Tag>
                        </div>
                        <div class="form_label" v-if="item.type == 'tab4'">
                            <span v-if="item.isrequire == 1" style="color: red; margin-right: 2px">*</span>
                            <span v-if="isDetail != 'true' && isEdit!='true'">{{ index - 1 }}.</span>
                            <span v-if="isDetail == 'true' || isEdit == 'true'">{{ index + 1 }}.</span>
                            {{ item.title }}
                            <Tag color="primary" style="margin-left: 15px">比重题</Tag>
                        </div>
                        <RadioGroup v-model="vertical" vertical v-if="item.type == 'tab1'">
                            <Radio :label="itemName.content" v-for="itemName in item.optionsArr" :key="itemName">
                                <span>{{ itemName.content }}</span>
                            </Radio>
                        </RadioGroup>
                        <CheckboxGroup v-model="fruit" style="display: flex; flex-direction: column" v-if="item.type == 'tab2'">
                            <Checkbox :label="itemName.content" style="margin: 5px 0" v-for="itemName in item.optionsArr" :key="itemName"></Checkbox>
                        </CheckboxGroup>
                        <Input style="margin-top: 5px" type="textarea" placeholder="请输入" v-model.trim="formData.office_address" v-if="item.type == 'tab3'" />
                        <Rate v-model="ratevalue" :count="5" v-if="item.type == 'tab4' && item.status == 1" />
                        <Rate v-model="ratevalue" :count="10" v-if="item.type == 'tab4' && item.status == 2" />
                        <div style="display: flex; position: absolute; top: 16px; right: 94px" v-if="isDetail != 'true'">
                            <div class="btn" @click="toEdit(item, index)">编辑</div>
                            <div class="btn" @click="copy(item, index)">复制</div>
                            <div class="btn" @click="top(item, index)">上移</div>
                            <div class="btn" @click="bottom(item, index)">下移</div>
                            <div class="btn" @click="toDel(index)">删除</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div style="display: flex; flex-direction: column; margin-left: 126px; margin-top: 20px">
                <div class="form_label">
                    2.这是一个问题题目
                    <tag>多选题</tag>
                </div>
                <CheckboxGroup v-model="fruit" style="display: flex; flex-direction: column">
                    <Checkbox :label="itemName.content" style="margin: 5px 0" v-for="itemName in item.optionsArr2" :key="itemName"></Checkbox>
                </CheckboxGroup>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 126px; margin-top: 20px">
                <div class="form_label">
                    3.这是一个问题题目
                    <tag>问答题</tag>
                </div>

                <Input style="margin-top: 5px" type="textarea" placeholder="请输入" v-model.trim="formData.office_address" />
            </div>
            <div style="display: flex; flex-direction: column; margin-left: 126px; margin-top: 20px">
                <div class="form_label">
                    4.这是一个问题题目
                    <tag>比重题</tag>
                </div>
                <Rate v-model="ratevalue" :count="10" />
            </div> -->
        </Card>
        <!-- <Card title="调研数据" dis-hover v-if="isDetail == 'true'">
            <div class="form_item">
                <div class="form_label">调研次数</div>
                <div>{{ formData.pv_count }}次</div>
            </div>
            <div class="form_item">
                <div class="form_label">调研人数</div>
                <div>{{ formData.uv_count }}人</div>
            </div>
        </Card> -->
        <Card title="发布设置" dis-hover v-if="isDetail != 'true'">
            <div class="form_item">
                <div class="form_label must_input">发布时间</div>
                <div v-if="isDetail == 'true'">{{ formData.publish_at }}</div>
                <DatePicker v-else type="datetime" v-model="formData.publish_at" placeholder="请选择发布时间" style="width: 200px" />
            </div>
            <div class="form_item">
                <div class="form_label must_input">结束时间</div>
                <div v-if="isDetail == 'true'">{{ formData.ended_at }}</div>
                <DatePicker v-else type="datetime" placeholder="请选择结束时间" v-model="formData.ended_at" style="width: 200px" />
            </div>
            <div class="form_item" style="position: absolute; z-index: -999">
                    <div class="form_label must_input">登录账号</div>
                    <Input class="width_300" v-model.trim="yc" :maxlength="15" show-word-limit></Input>
                </div>
            <div class="form_item">
                <div class="form_label must_input">可否多次填写</div>
                <div v-if="isDetail == 'true'">
                    <span v-if="formData.is_multi == 1">可以</span>
                    <span v-if="formData.is_multi == 0">不可以</span>
                </div>
                <RadioGroup v-else v-model="formData.is_multi" type="button" button-style="solid">
                    <Radio label="1">可以</Radio>
                    <Radio label="0">不可以</Radio>
                </RadioGroup>
            </div>
        </Card>
        <div class="mt_50 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">返回</i-button>
            <i-button v-if="isDetail != 'true'" class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirm">新建并发布</i-button>
        </div>
        <Modal :closable="false" v-model="showCreate" title="新增题目" width="700" :loading="ajaxLoading">
            <Tabs type="card" v-model="questionsValue" height="246" @on-click="tabsClick" style="z-index: 66666">
                <TabPane label="单选题" name="tab1" context-menu></TabPane>
                <TabPane label="多选题" name="tab2" context-menu></TabPane>
                <TabPane label="问答题" name="tab3" context-menu></TabPane>
                <TabPane label="比重题" name="tab4" context-menu></TabPane>
            </Tabs>
            <div class="form_item" style="margin-left: -118px" v-if="model.type == 'tab1'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model.title" placeholder="请输入问题标题"></Input>
            </div>
            <div class="form_item" style="margin-left: -118px" v-if="model.type == 'tab2'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model.title" placeholder="请输入问题标题"></Input>
            </div>
            <div class="form_item" style="margin-left: -118px" v-if="model.type == 'tab3'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model.title" placeholder="请输入问题标题"></Input>
            </div>
            <div class="form_item" style="margin-left: -118px" v-if="model.type == 'tab4'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model.title" placeholder="请输入问题标题"></Input>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model.type == 'tab1'">
                <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="addOptions1" style="margin-top: 10px; width: 100px; position: relative; left: 500px; bottom: 11px">添加选项</i-button>
                <RadioGroup v-model="vertical" vertical>
                    <div style="display: flex; margin: 5px 0" v-for="item in model.optionsArr" :key="item.type">
                        <Radio label=""></Radio>
                        <Input placeholder="请输入选项" v-model="item.content"></Input>
                    </div>
                </RadioGroup>
                <div class="form_item" style="margin-left: -118px; margin-top: 12px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model.type == 'tab2'">
                <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="addOptions2" style="margin-top: 10px; width: 100px; position: relative; left: 500px; bottom: 11px">添加选项</i-button>
                <CheckboxGroup v-model="fruit" style="display: flex; flex-direction: column">
                    <div style="display: flex; margin: 5px 0" v-for="item in model.optionsArr" :key="item">
                        <Checkbox label=""></Checkbox>
                        <Input placeholder="请输入选项" v-model="item.content"></Input>
                    </div>
                </CheckboxGroup>
                <div class="form_item" style="margin-left: -118px; margin-top: 12px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model.type == 'tab3'">
                <div class="form_item" style="margin-left: -118px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model.type == 'tab4'">
                <div class="form_item" style="margin-left: -118px; margin-top: -12px">
                    <div class="form_label must_input">类型</div>
                    <RadioGroup v-model="model.status" type="button" button-style="solid">
                        <Radio label="1">五星</Radio>
                        <Radio label="2">十星</Radio>
                    </RadioGroup>
                </div>
                <div class="form_item" style="margin-left: -118px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
            </div>
        </Modal>
        <Modal :closable="false" v-model="editFlag" title="编辑题目" width="700" :loading="ajaxLoading">
            <!-- <Tabs type="card" v-model="questionsValue"  height="246" @on-click="tabsClick" style="z-index: 66666">
                <TabPane  label="单选题" name="tab1" context-menu></TabPane>
                <TabPane  label="多选题" name="tab2" context-menu></TabPane>
                <TabPane label="问答题" name="tab3" context-menu></TabPane>
                <TabPane label="比重题" name="tab4" context-menu></TabPane>
            </Tabs> -->
            <div class="form_item" style="margin-left: -118px" v-if="model1.type == 'tab1'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model1.title" placeholder="请输入问题标题"></Input>
            </div>
            <div class="form_item" style="margin-left: -118px" v-if="model1.type == 'tab2'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model1.title" placeholder="请输入问题标题"></Input>
            </div>
            <div class="form_item" style="margin-left: -118px" v-if="model1.type == 'tab3'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model1.title" placeholder="请输入问题标题"></Input>
            </div>
            <div class="form_item" style="margin-left: -118px" v-if="model1.type == 'tab4'">
                <div class="form_label must_input">问题标题</div>
                <Input v-model="model1.title" placeholder="请输入问题标题"></Input>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model1.type == 'tab1'">
                <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="addOptions1" style="margin-top: 10px; width: 100px; position: relative; left: 500px; bottom: 11px">添加选项</i-button>
                <RadioGroup v-model="vertical" vertical>
                    <div style="display: flex; margin: 5px 0" v-for="item in model1.optionsArr" :key="item.type">
                        <Radio label=""></Radio>
                        <Input placeholder="请输入选项" v-model="item.content"></Input>
                    </div>
                </RadioGroup>
                <div class="form_item" style="margin-left: -118px; margin-top: 12px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model1.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model1.type == 'tab2'">
                <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="addOptions2" style="margin-top: 10px; width: 100px; position: relative; left: 500px; bottom: 11px">添加选项</i-button>
                <CheckboxGroup v-model="fruit" style="display: flex; flex-direction: column">
                    <div style="display: flex; margin: 5px 0" v-for="item in model1.optionsArr" :key="item.type">
                        <Checkbox label=""></Checkbox>
                        <Input placeholder="请输入选项" v-model="item.content"></Input>
                    </div>
                </CheckboxGroup>
                <div class="form_item" style="margin-left: -118px; margin-top: 12px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model1.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model1.type == 'tab3'">
                <div class="form_item" style="margin-left: -118px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model1.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; margin-top: 20px" v-if="model1.type == 'tab4'">
                <div class="form_item" style="margin-left: -118px; margin-top: -12px">
                    <div class="form_label must_input">类型</div>
                    <RadioGroup v-model="model1.status" type="button" button-style="solid">
                        <Radio label="1">五星</Radio>
                        <Radio label="2">十星</Radio>
                    </RadioGroup>
                </div>
                <div class="form_item" style="margin-left: -118px">
                    <div class="form_label must_input">是否必填</div>
                    <RadioGroup v-model="model1.isrequire" type="button" button-style="solid">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="editFlag = false">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmEdit1">确定</Button>
            </div>
        </Modal>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqGroupList, addAgent, updateAgent, readAgent, paperSave, paperRead } from '@/lib/request/auth';
import city from '@/components/city';
import { reqMerchantCreateAccount, reqMerchantSaveAccount } from '@/lib/request/auth2';
import { getCityTitles } from '@/lib/city.data';
import UploadImage from '@/components/uploadImage';

export default {
    name: 'serviceProviderAdd',
    components: {
        city,
        UploadImage,
    },
    data() {
        return {
            yc:'',
            dataColumn: [
                { slot: 'id', title: '编号' },
                { slot: 'name', title: '问题标题' },
                { slot: 'type', title: '类型' },
                { slot: 'isrequire', title: '是否必填' },
                // { key: 'receiveNum', title: '接单情况（次）' },
                { slot: 'manager', title: '问题描述' },
            ],
            tableKey: 'tableKey',
            questionsValue: 'tab1',
            questionsList: [
                {
                    type: 'tab1',
                    title: '',
                    optionsArr: [
                        {
                            content: '',
                        },
                    ],
                    isrequire: 0,
                },
                {
                    type: 'tab2',
                    title: '',
                    optionsArr: [
                        {
                            content: '',
                        },
                    ],
                    isrequire: 0,
                },
            ],
            model: {
                optionsArr: [{ content: '' }],
                isrequire: 0,
                type: 'tab1',
            },
            model1: {
                optionsArr: [{ content: '' }],
                isrequire: 0,
                type: 'tab1',
            },
            type: 'tab1',
            showCreate: false,
            ratevalue: 0,
            fruit: '',
            vertical: '',
            title: '添加题目',
            formData: {
                id: null,
            },
            address_code: [],
            address_note: null,
            roleList: [],
            address: [],
            isEdit: false,
            isDetail: false,
            arrPic: [],
            editFlag: false,
            tabEdit: 'name1',
            editIndex: 0,
        };
    },
    mounted() {
        this.routeParamsInjectFormData(['address_code', 'address_note']);
        this.isEdit = this.$route.query.isEdit;
        this.formData.id = this.$route.query.id;
        this.isDetail = this.$route.query.isDetail;
        console.log(this.$route.query.isEdit, 'this.$route.query.isEdit');
        this.title = this.isEdit == 'true' ? '编辑问卷信息' : '新增问卷信息';
        if (this.isDetail == 'true') {
            this.title = '详情';
        }

        this.getRoleList();
        this.getDetail();
    },
    watch: {
        //
    },
    methods: {
        toEdit(row, index) {
            console.log(row, 'rwowaaa');
            console.log(index, 'index');
            this.model1 = {};
            this.editFlag = true;
            this.model1 = Object.assign({}, row);
            this.tabEdit = row.type;
            this.editIndex = index;
            // this.questionsList[index]
        },
        bottom(row, index) {
            this.questionsList.splice(index, 1);
            this.questionsList.splice(index + 1, 0, row);
        },
        top(row, index) {
            // arr.splice(index, 1);
            //  arr.splice(index - 1, 0, element);
            console.log(index, 'index000');
            if (index < 1) {
                this.fadeWarning('已经置顶，无法上移');
                return;
            }
            this.questionsList.splice(index, 1);
            this.questionsList.splice(index - 1, 0, row);
        },
        copy(row, index) {
            this.questionsList.splice(2, 0, row);
        },
        toDel(index) {
            this.questionsList.splice(index, 1);
        },
        addOptions2() {
            this.model.optionsArr.push({ content: '' });
            this.$forceUpdate();
        },
        addOptions1() {
            this.model.optionsArr.push({ content: '' });
            this.$forceUpdate();
            console.log(this.model.optionsArr, 'this.model.optionsArr1');
        },
        onCancelCreate() {
            this.showCreate = false;
        },
        tabsClick(name) {
            this.model.type = name;
            if (name == 'tab2') {
                this.model.optionsArr = [{ content: '' }];
                this.model.isrequire = '0';
                this.model.title = '';
            }
            if (name == 'tab1') {
                this.model.optionsArr = [{ content: '' }];
                this.model.isrequire = '0';
                this.model.title = '';
            }
            if (name == 'tab3') {
                this.model.isrequire = '0';
                this.model.title = '';
            }
            if (name == 'tab4') {
                this.model.status = '1';
                this.model.title = '';
                this.model.isrequire = '0';
            }
            this.$forceUpdate();
        },
        onConfirmCreate() {
            // if(this.model.title1 == '' || this.model.title2 == '' || this.model.title3 == '' || this.model.title4 == ''){
            //     return this.fadeWarning('请输入标题');
            // }
            this.questionsList.push(this.model);
            this.showCreate = false;

            console.log(this.questionsList, 'this.questionsList');
        },
        onConfirmEdit1() {
            this.model = this.model1;
            this.questionsList.splice(this.editIndex, 1);
            this.questionsList.splice(this.editIndex, 0, this.model);
            this.editFlag = false;
        },
        onAdd() {
            this.questionsValue = 'tab1';
            this.showCreate = true;
            this.model = {};
            this.model.type = 'tab1';
            this.model.optionsArr = [{ content: '' }];
            this.model.title = '';
            this.model.isrequire = '0';
        },
        //单个图片上传成功
        onSuccessUploadImg() {
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        getRoleList() {
            this.ajaxLoading = true;
            reqGroupList({
                groupId: 6,
            })
                .then(res => {
                    let list = res?.data?.list || [];
                    this.roleList = list;
                })
                .finally(res => {
                    this.ajaxLoading = false;
                });
        },
        //获取详情
        getDetail() {
            if (this.isEdit == 'true') {
                this.showAjaxLoading();
                paperRead({ id: this.formData.id })
                    .then(res => {
                        this.formData = res.data;
                        this.questionsList = JSON.parse(res.data.questions);
                        console.log(this.questionsList, 'this.formData1111');
                        // let arr = JSON.parse(this.formData.area);
                        // this.formData.areaarr = arr[0];
                        // this.address = arr[1];
                        // console.log(this.address, 'address');
                        // this.dataInjectFormData(res);
                        // this.arrPic = this.formData.pic.split(this.picSeq);
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirm() {
            // this.formData.area = this.address_code;
            console.log(this.address_code, 'address_code');
            this.onConfirmSave();
            // this.isEdit == 'true' ? this.onConfirmEdit() : this.onConfirmSave();
        },
        onConfirmSave() {
            // this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { title, publish_at, ended_at, is_multi } = params || {};
            let vs = [
                { value: title, tip: '请输入问卷名称' },
                // { value: publish_at, tip: '请选择发布时间' },
                // { value: ended_at, tip: '请选择结束时间' },
                { value: is_multi, tip: '请选择可否多次填写' },
            ];
            // let vs = [];
            var questionsList = this.questionsList.filter(item => item.title != '');
            console.log(questionsList, 'questionsList');
            params.questions = JSON.stringify(questionsList);
            hm.validateForm(vs)
                .then(() => {
                    var publish_at1 = this.formData.publish_at.getTime();
                    var ended_at1 = this.formData.ended_at.getTime();
                    if (publish_at1 > ended_at1) {
                        this.fadeWarning('发布时间不能小于结束时间');
                        this.hideAjaxLoading();
                        return;
                    }
                    paperSave(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        onConfirmEdit() {
            console.log(this.formData, 'this.formData');
            let address_code = this.formData.areaarr;
            console.log(getCityTitles(address_code), 'address_title');
            this.showAjaxLoading();
            this.formData.type = 1;
            this.formData.pid = 0;
            let addressObj = [];
            addressObj.push(this.formData.areaarr);
            addressObj.push(getCityTitles(address_code));
            this.formData.area = JSON.stringify(addressObj);
            console.log(this.formData.area, 'this.formData.area');
            let params = window.structuredClone(this.formData);
            // let { name, userName, userTel } = params || {};
            // let vs = [
            //     { value: name, tip: '请输入商务商名称' },
            //     { value: userName, tip: '请输入联系人' },
            //     { value: userTel, tip: '请输入手机号' },
            // ];
            let vs = [];

            hm.validateForm(vs)
                .then(() => {
                    if (address_code.length <= 0) {
                        this.fadeWarning('请选择公司地址');
                        this.hideAjaxLoading();
                        return;
                    }
                    let address_title = getCityTitles(address_code);
                    params.address = address_title.join(',') + ';' + this.address_note || '';
                    params.address_code = address_code.join(',');
                    updateAgent(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);

                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.btn {
    margin: 0 20px;
    color: blue;
    cursor: pointer;
}
</style>
